/**
 * This Module is an Auto Compute of an object
 * that will be exported by default.
 *
 * It took all the Modules present in module folders
 * and will append the corresponding key and related
 * React component to the main object.
 *
 * This is a usefull method to auto add modules
 * to ModuleMapping that would be used by AppRouter
 */

import * as Modules from '../../../modules';

const ModuleMapping = Object

  .getOwnPropertyNames(Modules)

  .filter(mod => !mod.match(/__esModule/))

  .reduce((mappings, mod) => {
    // eslint-disable-next-line no-param-reassign
    mappings[mod] = Modules[mod];
    return mappings;
  }, {});

export default ModuleMapping;
