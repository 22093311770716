import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Container, { ContainerProps } from './Atoms/Container';
import ContainerTitle, { ContainerTitleProps } from './Atoms/ContainerTitle';
import ContainerError, { ContainerErrorProps } from './Atoms/ContainerError';
import ContainerLoader, { ContainerLoaderProps } from './Atoms/ContainerLoader';

import CSEALogo from '../../../assets/LogoCsea.png';
import SestuLogo from '../../../assets/LogoSestu.png';


/* --------
 * Component Styles
 * -------- */
const useStyle = makeStyles((theme) => ({

  viewLogo: {
    width: '50%',
    margin: '0 auto',
    marginBottom: theme.spacing(2)
  }

}));


/* --------
 * Component Props
 * -------- */
export interface PageViewProps extends
  ContainerProps,
  ContainerTitleProps,
  ContainerErrorProps,
  ContainerLoaderProps {

    useLogo?: boolean;

  }


/* --------
 * Component Definition
 * -------- */
export function PageView(props: React.PropsWithChildren<PageViewProps>): React.ReactElement {

  const {
    appendCopyright,
    children,
    icon: IconComponent,
    maxWidth,
    title,
    subTitle,
    error,
    errorTitle,
    errorContent,
    errorButton,
    onErrorButtonClick,
    loading,
    useLogo
  } = props;

  const classes = useStyle();

  const appLogo = React.useMemo(
    () => {
      /** Get the first part of location */
      const domain = window.location.hostname.split('.')[0].toLowerCase();

      if (domain === 'sestu') {
        return SestuLogo;
      }

      return CSEALogo;
    },
    []
  );

  return (
    <Container appendCopyright={appendCopyright} maxWidth={maxWidth}>

        {useLogo && (
          <img src={appLogo} alt='logo' className={classes.viewLogo} />
        )}

        {/* Display Header */}
        <ContainerTitle
          icon={IconComponent}
          title={title}
          subTitle={subTitle}
        />

        {/* Display Error */}
        <ContainerError
          error={error}
          errorContent={errorContent}
          errorTitle={errorTitle}
          errorButton={errorButton}
          onErrorButtonClick={onErrorButtonClick}
        />

        {/* Display Loader or Children */}
        {(
          loading
            ? <ContainerLoader loading={loading} />
            : children
        )}

    </Container>
  );
}

PageView.defaultProps = {
  appendCopyright: true,
  errorContent: 'Ti preghiamo di riprovare più tardi',
  errorTitle: 'Si è verificato un Errore',
  maxWidth: 'xs',
  useLogo: true
} as Partial<PageViewProps>;
