import { EventEmitter } from 'events';

import * as localforage from 'localforage';

import axios, { AxiosResponse, AxiosError } from 'axios';
import dayjs from 'dayjs';

import {
  ClientSystem,
  UserData,
  AuthState,
  ClientRequestConfig,
  AuthorizationDto,
  GenericAPIResponse,
  LocalDBAuthData
} from './client.interface';

import { will } from '../../utils/will';

import { EcoCenterByFiscalCode } from '../../interfaces';


/* --------
 * Client Definition
 * -------- */
export class Client {

  /* --------
   * Singleton Props and Functions
   * -------- */
  private static _instance: Client = null;

  public static getInstance(): Client {
    if (!Client._instance) {
      Client._instance = new Client();
    }

    return Client._instance;
  }


  /* --------
   * LocalForage Configuration
   * -------- */
  private static localDB = localforage.createInstance({
    name        : 'CDRScheduler',
    version     : 1.0,
    storeName   : 'ClientData',
    description : 'Container of Client Data and Auth'
  });


  /* --------
   * Axios Client Init
   * -------- */
  private static client = axios.create({
    baseURL: 'https://webapicore.cantieridigitali.net/',
    timeout: 30000,
    validateStatus: status => status === 200
  });

  private static prepareUrl = (_url: string): string => typeof _url !== 'string' || !_url.length
    ? null
    : encodeURI(_url.replace(/(^\/*)|(\/*$)/, ''))


  /* --------
   * Static Functions and Props
   * -------- */
  private static isDebugActive = true && process.env.NODE_ENV === 'development';

  private static lastDebug = Date.now();

  private static debug(...args: any) {
    /** Check if Debug is Activated */
    if (!Client.isDebugActive) {
      return;
    }
    /** Get Timestamp and Elapsed from last Debug */
    const now = Date.now();
    const elapsed = now - Client.lastDebug;
    /** Write the Message on Console */
    window.console.info(`[ + ${elapsed}ms ] - Client Debug\n`, ...args);
    /** Set last debug timestamp */
    Client.lastDebug = now;
  }


  /* --------
   * Instance Props
   * -------- */
  private events = new EventEmitter();

  private system: ClientSystem = {
    autoConfirmAppointment: false,
    isLoaded: false,
    isOperatorPerformer: false,
    operatorID: null,
    proposedFiscalCode: ''
  };

  private token: string = null;

  public userData: UserData = null;

  public ecoCenter: EcoCenterByFiscalCode = null;


  /* --------
   * Constructor
   * Is `private` because the Client
   * class must be a Singleton and could
   * not been directly instantiated by the App
   * -------- */
  private constructor() {
    /** Await client Initialization */
    this._init()
      .finally(() => {
        /** Set client loaded */
        this.system.isLoaded = true;
        /** Fire the onAuthStateChanged */
        this.onAuthStateChanged();
      });
  }


  /* --------
   * Init Function
   * -------- */
  private async _init(): Promise<void> {
    Client.debug('Initializing Client');
    await new Promise((resolve) => setTimeout(resolve, 1000));

    /** Check if data exists on local db */
    const [getDataError, localData] = await will(
      Client.localDB.getItem<LocalDBAuthData>('authData')
    );

    /** Before Loading saved data, check if proposed data exists */
    if (window?.location?.search) {
      /** Load URL Parameters */
      const queryParams = new URLSearchParams(window.location.search);
      /** Save data */
      this.system.autoConfirmAppointment = queryParams.get('autoConfirm') === '1';
      this.system.proposedFiscalCode = queryParams.get('fiscalCode') ?? '';
      this.system.operatorID = parseInt(queryParams.get('operatorID'), 10);
      this.system.isOperatorPerformer = !Number.isNaN(this.system.operatorID) && typeof this.system.operatorID === 'number';
    }

    if (this.system.proposedFiscalCode.length) {
      Client.debug('A proposed fiscal code exists, unauthorize to purge local data');
      await this.unauthorize({ callEvent: false });
      return;
    }

    /** Check no error occured while loading data */
    if (getDataError) {
      Client.debug('An error occured while reading data from localDB', { getDataError });
      await this.unauthorize({ callEvent: false });
      throw getDataError;
    }

    /** Check Local Data exists */
    if (!localData) {
      Client.debug('No local data exists, stop initialization');
      return;
    }

    /** Check session is not expired */
    if (dayjs(localData.lastLogin).add(12, 'hour').isBefore(dayjs())) {
      Client.debug('Local Data has been found but session is Expired');
      return;
    }

    Client.debug('Found valid local data', { localData });

    /** Strip token from user data */
    const {
      token: oldToken,
      userData,
      ecoCenter
    } = localData;

    Client.debug('Verify Authorization Token');

    const [err, token] = await will(this.post<string>('/cdr/verifyauthorization', { withToken: oldToken }));

    if (err || !token) {
      Client.debug('Authorization is invalid. Purge Client');
      await this.unauthorize({ callEvent: false });
      return;
    }

    Client.debug('Update last login time & Token on Local DB');

    /** Update Local Data lastLogin value */
    await will(Client.localDB.setItem<LocalDBAuthData>('authData', {
      ...localData,
      token,
      lastLogin: dayjs(localData.lastLogin).add(2, 'hour').valueOf()
    }));

    /** Save Data into Client */
    this.token = token;
    this.userData = userData;
    this.ecoCenter = ecoCenter;

  }


  /* --------
   * Side Private Functions
   * -------- */
  public async tryToAuthorizeToCDR(authorizationDto: AuthorizationDto, ecoCenter: EcoCenterByFiscalCode): Promise<boolean> {
    Client.debug('Trying Authorization Process', { authorizationDto });
    /** Make a POST Request passing Authorization Data */
    const [authError, authData] = await will(this.request<UserData & { token: string }>({
      url       : '/cdr/authorize',
      data      : authorizationDto,
      method    : 'POST',
      withToken : false
    }));

    if (authError) {
      Client.debug('An AuthError occured', { authError });
      throw authError;
    }

    if (!authData) {
      Client.debug('An Invalid Auth Response has been received', { authData });
      throw new Error('Invalid Auth Response');
    }

    const {
      token,
      ...userData
    } = authData;

    if (!token) {
      Client.debug('An Invalid Auth Token has been received', { authData });
      throw new Error('Invalid Auth Token');
    }

    /** Save Token */
    Client.debug('Set token and userData', { token, userData });
    this.userData = userData;
    this.token = token;
    this.ecoCenter = ecoCenter;

    /** Save local data on local db */
    const [saveDataError] = await will(Client.localDB.setItem<LocalDBAuthData>('authData', {
      userData,
      token,
      ecoCenter,
      lastLogin: dayjs().valueOf()
    }));

    if (saveDataError) {
      Client.debug('An error occured while saving local data', { saveDataError });
      throw saveDataError;
    }

    Client.debug('Local data correctly saved on client');

    /** Call the System State Changed */
    this.onAuthStateChanged();

    return !!authData;
  }


  public async unauthorize(options: { callEvent: boolean } = { callEvent: true }): Promise<void> {
    Client.debug('Removing local data from localDb');
    const [removeDataError] = await will(Client.localDB.removeItem('authData'));

    if (!removeDataError) {
      Client.debug('Removing UserData and Token from Client');
      this.userData = null;
      this.token = null;
      this.ecoCenter = null;

      if (options.callEvent) {
        this.onAuthStateChanged();
      }
    }
    else {
      Client.debug('An error occured while removing data from local db', { removeDataError });
    }
  }


  /* --------
   * Event Handlers
   * -------- */
  public onAuthStateChanged(callback?: (authState: AuthState) => void, context?: any): () => void {
    /** If no callback, fire the authChanged event */
    if (typeof callback !== 'function') {
      Client.debug('Emitting authChanged Event', { authState: this.authState });
      this.events.emit('authChanged');
      return;
    }

    /** Create a new Listener */
    Client.debug('A new callback has been registered for authChanged event', { callback, context });
    this.events.on('authChanged', () => {
      callback.apply(context, [this.authState]);
    });

    /** Return the unsubscribe function */
    return () => {
      this.events.off('authChanged', callback);
      Client.debug('An observer for authChanged event has been stopped', { callback });
    };
  }


  /** Get current client Auth State */
  public get authState(): AuthState {
    return {
      isLoaded: this.system.isLoaded,
      userHasAuth: !!this.userData && !!this.token && !!this.ecoCenter
    };
  }

  /** Get Proposed Fiscal Code */
  public get proposedFiscalCode(): string {
    return typeof this.system.proposedFiscalCode === 'string'
      ? this.system.proposedFiscalCode
      : '';
  }

  /** Get if appointment must be auto confirmed */
  public get autoConfirmAppointment(): boolean {
    return !!this.system.autoConfirmAppointment;
  }

  /** Get the Operator ID */
  public get operatorID(): number {
    return this.system.isOperatorPerformer
      ? this.system.operatorID
      : null;
  }


  /* --------
   * Client Request Functions
   * -------- */
  public get<T>(url: string, options?: Omit<ClientRequestConfig, 'url' | 'method' | 'data'>) {
    return this.request<T>({
      ...options,
      url,
      method: 'GET'
    });
  }

  public post<T>(url: string, options?: Omit<ClientRequestConfig, 'url' | 'method'>) {
    return this.request<T>({
      ...options,
      url,
      method: 'POST'
    });
  }

  public put<T>(url: string, options?: Omit<ClientRequestConfig, 'url' | 'method'>) {
    return this.request<T>({
      ...options,
      url,
      method: 'PUT'
    });
  }

  public delete<T>(url: string, options?: Omit<ClientRequestConfig, 'url' | 'method'>) {
    return this.request<T>({
      ...options,
      url,
      method: 'DELETE'
    });
  }

  private async request<T = GenericAPIResponse>(config: ClientRequestConfig): Promise<T> {
    /** Deconstruct Config */
    const {
      url: _url,
      method,
      withToken = true,
      params = {},
      data = {}
    } = config;

    /** Prepare the Request URL */
    const url = Client.prepareUrl(_url);

    if (!url) {
      throw new Error('client/invalid-api-url');
    }

    /** Build Token */
    let token: string;

    if (withToken) {
      /** If typeof withToken is bool, get the default token */
      if (typeof withToken === 'boolean') {
        token = this.token;
      }
      /** Else if withToken is a string, use as token */
      else if (typeof withToken === 'string') {
        token = withToken;
      }
    }

    if (withToken && !token) {
      throw new Error('client/invalid-token');
    }

    /** Build request Header */
    const headers = {
      ...(withToken && { Authorization: `Bearer ${token}` })
    };

    /** Make the Request */
    try {
      Client.debug(`Performing a '${config.method}' Request to '${config.url}'`, { headers, params, data });
      const response = await Client.client({
        url,
        method,
        headers,
        params,
        data
      }) as AxiosResponse<T>;

      Client.debug(`Response received from '${config.url}'`, { response });
      return response.data;
    }
    catch (e) {
      /** Check error is an AxiosError */
      if (e?.isAxiosError) {
        Client.debug(`An error has been received from '${config.url}', parsed as Axios Error`, { response: e.response });
        /** If response is equal to Unauthorized, clear the Client */
        if (e.response.status === 401) {
          Client.debug('Client authorization is out of time');
          /** Unauthorize Client */
          await this.unauthorize();
        }
        throw (e as AxiosError).response;
      }

      Client.debug(`An error has been received from '${config.url}'`, { e });
      throw e instanceof Error ? e : new Error('Undefined request error');
    }
  }

}
