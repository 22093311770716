import * as React from 'react';

import MUIContainer from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

import Copyright from './Copyright';


/* --------
 * Container Props
 * -------- */
export interface ContainerProps {
  appendCopyright?: boolean;
  content?: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}


/* --------
 * Component Styles
 * -------- */
const useStyles = makeStyles((theme) => ({

  container: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },

  wrapper: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }

}));


/* --------
 * Component Definition
 * -------- */
export default function Container(props: React.PropsWithChildren<ContainerProps>): React.ReactElement {

  const {
    appendCopyright,
    content,
    children,
    maxWidth
  } = props;

  const classes = useStyles();

  return (
    <MUIContainer component='main' maxWidth={maxWidth} className={classes.container}>
      <div className={classes.wrapper}>

        {content || children}

        {appendCopyright && (
          <Copyright />
        )}

      </div>
    </MUIContainer>
  );
}
