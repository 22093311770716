import * as React from 'react';

import { RouteChildrenProps } from 'react-router-dom';

import dayjs from 'dayjs';

import * as _ from 'lodash';

import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';

import { withStyles, WithStyles, Theme, createStyles } from '@material-ui/core/styles';

import { useClient } from '../../hoc/with-client/client.context';

import { will } from '../../utils/will';
import { EcoCenterByFiscalCode } from '../../interfaces';
import { PageView } from '../../ui/PageView';

import { Input } from '../../component/Input';
import { Form } from '../../component/Form';
import { Select } from '../../component/Select';
import { Button } from '../../component/Button';

/* --------
 * Page Styles
 * -------- */
const styles = createStyles((theme: Theme) => ({

  selector: {
    marginTop: theme.spacing(2)
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

}));


/* --------
 * Page Props & State
 * -------- */
interface LoginProps extends RouteChildrenProps, WithStyles {}

interface LoginState {
  ecocenters: EcoCenterByFiscalCode[];
  error: string;
  fiscalCode: string;
  fiscalCodeError: string;
  isAuthorizing: boolean;
  isLoading: boolean;
  selectedEcoCenter: EcoCenterByFiscalCode;
  validFiscalCode: boolean;
  openedToUD: boolean;
}


/* --------
 * Page Component
 * -------- */
class Login extends React.Component<LoginProps, LoginState> {

  static openDay = dayjs(new Date(2020, 4, 18, 9, 0, 0, 0));

  /** State Definition */
  state: LoginState = (() => {

    const { proposedFiscalCode } = useClient();

    return {
      error: '',
      fiscalCode: proposedFiscalCode,
      fiscalCodeError: null,
      isAuthorizing: false,
      isLoading: false,
      validFiscalCode: false,
      ecocenters: [],
      selectedEcoCenter: null,
      openedToUD: dayjs().isAfter(Login.openDay)
    };

  })();

  componentDidMount() {
    /** If fiscal code has been proposed, load CDR */
    const { fiscalCode } = this.state;

    if (fiscalCode) {
      this.handleFiscalCodeChange({ target: { value: fiscalCode } } as React.ChangeEvent<HTMLInputElement>);
    }

    /** Check Milliseconds to Open Day */
    const msToOpenDay = Login.openDay.diff(dayjs(), 'ms');
    /** Set timeout to rerender at open day */
    if (msToOpenDay > 0) {
      setTimeout(() => {
        this.setState({
          openedToUD: true
        });
      }, msToOpenDay);
    }
  }

  handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    /** Prevent default form submit behaviour */
    e.preventDefault();
    /** Try to Authorize the Client */
    const {
      isAuthorizing,
      fiscalCode,
      selectedEcoCenter
    } = this.state;

    if (isAuthorizing || !fiscalCode || !selectedEcoCenter) {
      return;
    }

    this.setState({ isAuthorizing: true });

    const client = useClient();

    const [authError] = await will(client.tryToAuthorizeToCDR({
      fiscalCode,
      cdrCode: selectedEcoCenter.code,
      domain: selectedEcoCenter.domain,
      customerID: selectedEcoCenter.customerID
    }, selectedEcoCenter));

    if (authError) {
      this.setState({
        isAuthorizing : false,
        error         : authError ? (authError as Error)?.message ?? 'Error' : ''
      });
    }

  }

  handleFiscalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    /** Set the Fiscal Code and restore ecocenters */
    this.setState({
      error             : '',
      fiscalCode        : e.target.value,
      ecocenters        : [],
      isLoading         : true,
      validFiscalCode   : false,
      fiscalCodeError   : null,
      selectedEcoCenter : null
    }, this.getCDRByFiscalCode);
  }

  handleEcoCenterSelectChange = (e: React.ChangeEvent<{ value: string }>) => {
    const { value } = e.target;

    const { ecocenters } = this.state;

    this.setState({
      selectedEcoCenter: value
        ? (ecocenters.find(({ domain, id }) => (`${domain}-${id}` === value)) ?? null)
        : null
    });
  }

  getCDRByFiscalCode = _.debounce(async () => {
    const { fiscalCode, openedToUD } = this.state;

    const validFiscalCode = fiscalCode && (fiscalCode.length > 3);

    /** Check the Fiscal Code validity */
    if (!validFiscalCode) {
      this.setState({
        isLoading: false,
        fiscalCodeError: fiscalCode ? 'Inserisci un codice fiscale corretto' : ''
      });
      return;
    }
    /** Get the Client */
    const client = useClient();
    /** Make the CDR Request */
    const [cdrError, ecocenters] = await will(
      client.get<EcoCenterByFiscalCode[]>('/cdr', { params: { fiscalCode: encodeURIComponent(fiscalCode) }, withToken: false })
    );
    /** Set the new State */
    this.setState({
      error: cdrError ? (cdrError as Error)?.message ?? 'Error' : '',
      isLoading: false,
      validFiscalCode: true,
      ecocenters: !!cdrError
        ? []
        : openedToUD
          ? ecocenters
          : ecocenters.filter(({ totUND }) => totUND > 0)
    });
  }, 750);

  /** Page Render */
  render() {
    /** Get Classes from Styles */
    const { classes } = this.props;

    /** Get Loading state */
    const {
      error,
      isAuthorizing,
      isLoading,
      fiscalCodeError,
      ecocenters,
      validFiscalCode,
      selectedEcoCenter,
      fiscalCode,
      openedToUD
    } = this.state;

    return (
      <PageView
        error={error}
        icon={LockOutlinedIcon}
        title='Entra nel Portale'
        subTitle={(
          <div>
            Inserisci il tuo Codice Fiscale, la tua Partita IVA oppure il numero della tua Tessera EcoCard.<br />
            {!openedToUD && (
              <Box mt={1}>
                Si ricorda che l'accesso alle Isola Ecologiche CSEA è momentaneamente consentito alle sole UTENZE NON DOMESTICHE.
              </Box>
            )}
          </div>
        )}
      >
        <Form onSubmit={this.handleFormSubmit}>

          <Input
            loading={isLoading}
            disabled={isAuthorizing}
            error={!!fiscalCodeError}
            helperText={fiscalCodeError ?? (
              <span>
                Il numero della EcoCard deve avere almeno 4 cifre<br />
                <em>es: tessera 165 va inserita come 0165</em>
              </span>
            )}
            label='CF, P.IVA o EcoCard'
            autoComplete='fiscalCode'
            defaultValue={fiscalCode}
            onChange={this.handleFiscalCodeChange}
          />

          {validFiscalCode && !!ecocenters.length && (
            <Select
              label='Struttura'
              className={classes.selector}
              options={ecocenters.map(eco => ({
                label: eco.name,
                value: `${eco.domain}-${eco.id}`
              }))}
              value={selectedEcoCenter
                ? `${selectedEcoCenter.domain}-${selectedEcoCenter.id}`
                : ''}
              onSelectChange={this.handleEcoCenterSelectChange}
            />
          )}

          {validFiscalCode && !ecocenters.length && (
            <Box mt={2} textAlign='center'>
              <Typography component='h3' color='textSecondary'>
                Prenotazioni non disponibili
              </Typography>
            </Box>
          )}

          {selectedEcoCenter && (
            <Button
              fullWidth
              className={classes.submit}
              type='submit'
              variant='contained'
              color='primary'
              disabled={!selectedEcoCenter}
              loading={isAuthorizing}
              content={(
                isAuthorizing
                  ? 'Verifica Autorizzazione'
                  : 'Gestisci Prenotazioni'
              )}
            />
          )}

        </Form>
      </PageView>
    );
  }

}

export default withStyles(styles)(Login);
