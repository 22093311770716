import * as React from 'react';

import { Client } from '../../lib/client';

/** Interface to extend Component Props with Client */
export interface WithClientProps {
  client: Client;
}

/** Init the Client Context */
const ClientContext = React.createContext<Client>(null);
ClientContext.displayName = 'ClientContext';

/** Build the Context Provider HOC */
export function initClient<P extends {} = {}>(ChildComponent: React.ComponentType<P>): React.ComponentType<P> {
  return (childComponent?: P) => (
    <ClientContext.Provider value={Client.getInstance()}>
      <ChildComponent {...childComponent} />
    </ClientContext.Provider>
  );
}

/** Build the Context Consumer HOC */
export function withClient<P extends {} = {}>(ChildComponent: React.ComponentType<P>): React.ComponentType<P & WithClientProps> {
  return (childComponentProps?: P) => (
    <ClientContext.Consumer>
      {(client: Client) => <ChildComponent {...childComponentProps} client={client} />}
    </ClientContext.Consumer>
  );
}

/** Export a fake hook to get client instance anywhere */
export const useClient = () => Client.getInstance();
