import * as React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader, { CardHeaderProps } from '@material-ui/core/CardHeader';

import { makeStyles } from '@material-ui/core/styles';

/* --------
 * Component Style
 * -------- */
const useStyles = makeStyles((theme) => ({

  heroButton: {
    textDecoration: 'none',
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: 'pointer'
  }

}));


/* --------
 * Component Props
 * -------- */
export interface HeroButtonProps {
  icon?: React.ElementType;
  iconClassName?: string;
  onClick?: (e: React.MouseEvent) => void;
  title?: string;
  content?: string;
}


/* --------
 * Component Definition
 * -------- */
export function HeroButton(props: HeroButtonProps) {

  const {
    icon: IconComponent,
    iconClassName,
    onClick,
    title,
    content
  } = props;

  const handleButtonClick = (e) => {
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  const classes = useStyles();

  const headerProps: CardHeaderProps = {
    title,
    subheader: content
  };

  if (IconComponent) {
    headerProps.avatar = (
      <Avatar className={iconClassName}>
        <IconComponent />
      </Avatar>
    );
  }

  return (
    <Card onClick={handleButtonClick} className={classes.heroButton}>
      <CardHeader {...headerProps}/>
    </Card>
  );

}
