import * as React from 'react';
import { CalendarAvailability, getCalendarAvailabilities, SlotAvailability, getAvailability } from '../utils/calendarAvailabilities';
import { DatePicker } from '@material-ui/pickers';
import dayjs from 'dayjs';
import { will } from '../../../utils/will';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import clsx from 'clsx';
import { useClient } from '../../../hoc/with-client/client.context';


/* --------
 * Component Styles
 * -------- */
const styles = createStyles((theme: Theme) => ({

  availableDay: {
    backgroundColor: green[100],

    '&:hover': {
      backgroundColor: green[200]
    },

    '&.MuiPickersDay-daySelected': {
      backgroundColor: green[600]
    }
  },

  notAvailableDay: {
    backgroundColor: red[100]
  }

}));


/* --------
 * Component Props & State
 * -------- */
type ChooseDateClassNames = 'availableDay' | 'notAvailableDay';

interface ChooseDateProps extends WithStyles<ChooseDateClassNames> {
  onSelectDate: (date: dayjs.Dayjs, slots: SlotAvailability[]) => void;
}

interface ChooseDateState {
  isLoaded: boolean;
  availabilities: CalendarAvailability[];
  selectedDate: dayjs.Dayjs;
  initialDate: dayjs.Dayjs;
}


/* --------
 * Component Declaration
 * -------- */
class ChooseDate extends React.Component<ChooseDateProps, ChooseDateState> {

  state: ChooseDateState = {
    isLoaded: false,
    availabilities: [],
    selectedDate: null,
    initialDate: dayjs()
  };


  today: dayjs.Dayjs = dayjs();


  componentDidMount() {
    this.setCalendarAvailabilities();
  }


  setCalendarAvailabilities = async (date?: dayjs.Dayjs) => {
    const client = useClient();
    /** Get Calendar using Helpers */
    const [, availabilities] = await will(
      getCalendarAvailabilities(client.ecoCenter.slotCapacity, date)
    );

    /** Get initial date */
    let initialDate = dayjs();

    const firstAvailableDay = availabilities.find(({ availableCount, slots }) => !!availableCount && !!slots.length);

    if (firstAvailableDay) {
      initialDate = firstAvailableDay.slots[0].slot;
    }

    /** Set the new State */
    this.setState({
      isLoaded: true,
      availabilities: availabilities ?? [],
      initialDate
    });
  }


  handleDateChange = (date: dayjs.Dayjs) => {
    /** If no date, purge state */
    if (!date) {
      this.setState({ selectedDate: null }, this.daySelectCallback.bind(this, null));
      return;
    }

    /** Find date into availabilities */
    const dayAvailability = getAvailability(this.state.availabilities, date);

    /** Set the new State */
    this.setState({
      selectedDate: dayAvailability ? date : null
    }, this.daySelectCallback.bind(this, dayAvailability.availabilities));
  }


  daySelectCallback = (dayAvailability: SlotAvailability[]) => {
    /** Get Selected Date */
    const { selectedDate } = this.state;

    /** Get callback */
    const { onSelectDate } = this.props;

    if (!selectedDate || !dayAvailability) {
      onSelectDate(null, []);
    }
    else {
      onSelectDate(selectedDate, dayAvailability);
    }
  }


  shouldDisableDate = (date: dayjs.Dayjs): boolean => {
    /** Find if has availability */
    return !getAvailability(this.state.availabilities, date).availabilities.length;
  }


  renderDay = (date: dayjs.Dayjs, selectedDate: dayjs.Dayjs, isInCurrentMoth: boolean, dayComponent: React.ReactElement) => {
    /** Get if date is disabled */
    const { props: { disabled } } = dayComponent;

    /** Get classes from props */
    const { classes } = this.props;

    /** Check if date has availabilities */
    const dayAvailability = getAvailability(this.state.availabilities, date);

    /** Build new ClassName */
    const className = clsx(
      'MuiPickersDay-day',
      this.today.isSame(date, 'day') && 'MuiPickersDay-current',
      date.isSame(selectedDate, 'day') && 'MuiPickersDay-daySelected',
      disabled && 'MuiPickersDay-dayDisabled',
      !!dayAvailability.availabilities.length && classes.availableDay,
      !!dayAvailability.exists
        && !dayAvailability.availabilities.length
        && date.isAfter(this.today)
        && classes.notAvailableDay
    );

    /** Return the new Element */
    return React.cloneElement(dayComponent, { className });

  }


  render() {

    const {
      isLoaded,
      selectedDate,
      initialDate
    } = this.state;


    return (
      <DatePicker
        disablePast
        fullWidth
        disabled={!isLoaded}
        initialFocusedDate={initialDate}
        value={selectedDate}
        cancelLabel='Annulla'
        okLabel='Conferma'
        format='DD MMMM YYYY'
        label='Data Appuntamento'
        inputVariant='outlined'
        views={['date']}
        shouldDisableDate={this.shouldDisableDate}
        onMonthChange={this.setCalendarAvailabilities}
        onYearChange={this.setCalendarAvailabilities}
        renderDay={this.renderDay}
        onChange={this.handleDateChange}
      />
    );

  }

}


export default withStyles(styles)(ChooseDate);
