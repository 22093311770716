import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { red, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import EventRounded from '@material-ui/icons/EventRounded';
import EventBusyRounded from '@material-ui/icons/EventBusyRounded';
import HomeIcon from '@material-ui/icons/HomeRounded';

import { useClient } from '../../hoc/with-client/client.context';

import { PageView } from '../../ui/PageView';
import { Button } from '../../component/Button';
import { HeroButton } from '../../component/HeroButton';
import { RouteTo } from '../../router';
import RemoveAppointment from './Component/RemoveAppointment';


/* --------
 * Page Styles
 * -------- */
const useStyles = makeStyles((theme) => ({

  newAppointment: {
    backgroundColor: green[500]
  },

  removeAppointment: {
    backgroundColor: red[500]
  },

  logoutButton: {
    marginTop: theme.spacing(2)
  }

}));


/* --------
 * Component Definition
 * -------- */
function Dashboard(props: RouteComponentProps) {

  const client = useClient();

  const [isRemoving, setRemoving] = React.useState(false);
  const [isDeleted, setDeleted] = React.useState(false);

  const handleLogoutClick = () => {
    client.unauthorize();
  };

  const handleSchedule = () => {
    const { history } = props;
    history.push(RouteTo('Schedule'));
  };

  const handleRemoveStart = () => {
    setRemoving(true);
  };

  const handleRemoveEnd = (wasDeleted: boolean) => {
    setRemoving(false);

    if (wasDeleted) {
      setDeleted(true);
    }
  };

  const hideDeletedSnackbar = () => {
    setDeleted(false);
  };

  const classes = useStyles();

  return (
    <PageView
      icon={HomeIcon}
      title={[
        client.userData.firstName,
        client.userData.lastName
      ].filter(t => typeof t === 'string' && !!t.length).join(' ')}
      subTitle={client.ecoCenter.name}
      maxWidth='xs'
    >

      <HeroButton
        title='Nuovo Appuntamento'
        content='Effettua una prenotazione'
        icon={EventRounded}
        iconClassName={classes.newAppointment}
        onClick={handleSchedule}
      />

      <HeroButton
        title='Annulla Appuntamento'
        content='Annulla una prenotazione'
        icon={EventBusyRounded}
        iconClassName={classes.removeAppointment}
        onClick={handleRemoveStart}
      />

      <Button
        className={classes.logoutButton}
        variant='contained'
        color='secondary'
        content='Esci'
        onClick={handleLogoutClick}
      />

      {isRemoving && (
        <RemoveAppointment
          onDialogWantClose={handleRemoveEnd}
        />
      )}

      {isDeleted && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={7000}
          onClose={hideDeletedSnackbar}
        >
          <Alert severity='success'>
            Appuntamento Eliminato
          </Alert>
        </Snackbar>
      )}

    </PageView>
  );

}

export default Dashboard;
