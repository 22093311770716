import dayjs from 'dayjs';

import { useClient } from '../../../hoc/with-client/client.context';
import { will } from '../../../utils/will';
import { EcoCenterAgenda, EcoCenterAppointment } from '../../../interfaces';

export interface CalendarAvailability {
  day: string;
  slots: SlotAvailability[];
  maxCount: number;
  appointmentCount: number;
  availableCount: number;
}

export interface SlotAvailability {
  slot: dayjs.Dayjs;
  startLabel: string;
  endLabel: string;
  maxCount: number;
  appointmentCount: number;
  availableCount: number;
}

interface CalendarSkeleton {
  [key: string]: {
    day: dayjs.Dayjs;
    dayStr: string;
    slots: {
      [key: string]: {
        slot: dayjs.Dayjs;
        startLabel: string;
        endLabel: string;
        appointments: EcoCenterAppointment[];
      }
    }
  };
}

export async function getCalendarAvailabilities(maxAppointmentPerSlot: number, startingDate?: dayjs.Dayjs):
  Promise<CalendarAvailability[]> {
  /** Build the RefDate to load Data from Server */
  const today = dayjs();
  const refDate = startingDate ?? today;

  /** Get the Client */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const client = useClient();

  /** Assert start date is not before current date */
  let startdate = refDate.startOf('month');

  if (startdate.isBefore(today, 'day')) {
    startdate = today;
  }

  /** Make the API Request to get eco center agenda */
  const [err, agenda] = await will(
    client.get<EcoCenterAgenda>('/cdr/agenda', {
      params: {
        startdate: startdate.format('YYYY-MM-DD'),
        enddate: startdate.endOf('month').add(1, 'd').format('YYYY-MM-DD')
      }
    })
  );

  /** If an error occured, or no data has been returned, return an empty array */
  if (err || !agenda.calendars.length) {
    return [];
  }

  /** Init the Calendar Skeleton */
  const calendarSkeleton: CalendarSkeleton = {};

  /** Build slots per day */
  agenda.calendars
    /** Transfrom each into a valid dayjs element */
    .map(({ start, end }) => ({ start: dayjs(start), end: dayjs(end) }))
    /** Loop each data to build the slot skeleton */
    .forEach(({ end, start }) => {
      /** Get day Object */
      const day = start.startOf('day');
      const dayStr = day.format('YYYY-MM-DD');

      /** Check if skeleton per day exists */
      if (!calendarSkeleton[dayStr]) {
        calendarSkeleton[dayStr] = {
          day,
          dayStr,
          slots: {}
        };
      }

      /** Create a Cursor to build Slots */
      let cursor = start.clone();

      while (cursor.isBefore(end)) {
        /** Prebuild next cursor */
        let next = cursor.clone().add(30, 'minute');

        /** Assert next is in the range of start -> end */
        if (next.isAfter(end)) {
          next = end;
        }

        if (cursor.isAfter(today)) {
          /** Format Cursor */
          const cursorStr = cursor.format();

          /** Add the Slot if doesn't exists */
          if (!calendarSkeleton[dayStr].slots[cursorStr]) {
            calendarSkeleton[dayStr].slots[cursorStr] = {
              slot: cursor,
              startLabel: cursor.format('HH:mm'),
              endLabel: next.format('HH:mm'),
              appointments: []
            };
          }
        }

        /** Update Cursor */
        cursor = next;
      }

    });

  /** Add Appointments to the calendar skeleton */
  agenda.appointments.forEach(({ appointmentDate }) => {
    /** Build the Appointment DayJS Object */
    const appointment: dayjs.Dayjs = dayjs(appointmentDate).startOf('minute');
    /** Format */
    const dayStr = appointment.format('YYYY-MM-DD');
    const slotStr = appointment.format();
    /** If skeleton exists, append appointment to array */
    if (calendarSkeleton[dayStr]?.slots[slotStr]) {
      calendarSkeleton[dayStr].slots[slotStr].appointments.push({ appointmentDate });
    }
  });

  /** Build up the result */
  const result: CalendarAvailability[] = [];

  /** Loop each available day */
  Object
    /** Get all Formatted Days */
    .keys(calendarSkeleton)
    /** Remap to Calendar Skeleton */
    .map(dayStr => calendarSkeleton[dayStr])
    /** Loop each day skeleton */
    .forEach(({ slots: slotsSkeleton, dayStr }) => {
      /** Build the slots availabilities container */
      const slots: SlotAvailability[] = [];

      /** Loop each slots */
      Object
        .keys(slotsSkeleton)
        /** Remap to Slot Skeleton */
        .map(slotStr => slotsSkeleton[slotStr])
        /** Loop to build availabilities */
        .forEach(({ startLabel, endLabel, slot, appointments }) => {
          /** Push the Slot into Container */
          slots.push({
            slot,
            startLabel,
            endLabel,
            maxCount: maxAppointmentPerSlot,
            appointmentCount: appointments.length,
            availableCount: maxAppointmentPerSlot - appointments.length
          });
        });

      /** Push the calendar availability */
      result.push({
        day: dayStr,
        slots,
        maxCount: maxAppointmentPerSlot * slots.length,
        appointmentCount: slots.reduce((tot, slot) => tot + slot.appointmentCount, 0),
        availableCount: slots.reduce((tot, slot) => tot + slot.availableCount, 0)
      });

    });

  return result;

}


export function getAvailability(availabilities: CalendarAvailability[], date: dayjs.Dayjs):
  { exists: boolean, availabilities: SlotAvailability[] } {
  /** If no date, return empty */
  if (!date) {
    return { exists: false, availabilities: [] };
  }

  /** Format as String */
  const dayStr = date.format('YYYY-MM-DD');

  /** Find into availabilities */
  const dayAvailability = availabilities.find(({ day }) => day === dayStr);

  return {
    exists         : !!dayAvailability,
    availabilities : dayAvailability?.slots.filter(({ availableCount }) => !!availableCount) ?? []
  };
}
