import * as _ from 'lodash';

import { matchPath } from 'react-router-dom';


/* --------
 * Define the AppPage Interface
 * -------- */
export interface IAppPage {
  /** Page Name */
  name: TAppPage;

  /** Page Base Path, with params */
  path: string;

  /** Route Page, without Params, omit if page has no params */
  routePage?: string;

  /** Set if current page has navbar, default is true for private page */
  hasNavbar?: boolean;

  /** Set if current page has sidebar, default is true for private page */
  hasSidebar?: boolean;

  /** Set if page is Public */
  isPublic: boolean;

  /** Set if a page is hybrid (could be reached with or without auth) */
  isHybrid?: boolean;

  /** Set if page is a System page, a user could go to a System Page only if directly redirected */
  isSystem?: boolean;

  /** Set if route is exact */
  exact: boolean;

  /** Set the Page Title */
  title: string;
}


/* --------
 * Declare all App Pages
 * -------- */
export type TAppPage =
  | 'Login'
  | 'Dashboard'
  | 'Schedule'
  | 'Confirm';

const AppPages: IAppPage[] = [

  /** Login Page */
  { name: 'Login', path: '/auth/login', isPublic: true, exact: true, title: 'Login' },

  /** Dashboard Page */
  { name: 'Dashboard', path: '/', isPublic: false, exact: true, title: 'Home' },

  /** Schedule Appointment */
  { name: 'Schedule', path: '/schedule', isPublic: false, exact: true, title: 'Nuovo Appuntamento' },

  /** Confirm an Appointment */
  { name: 'Confirm', path: '/confirm', isPublic: true, isHybrid: true, exact: true, title: 'Conferma Appuntamento' }

];


/* --------
 * Define Page FallBack
 * -------- */
const defaultPublic: TAppPage = 'Login';
const defaultPrivate: TAppPage = 'Dashboard';


/* --------
 * Build the Function to Route between App Pages
 * -------- */
const RouteTo = (pathName: TAppPage, params?: { [key: string]: string }): string => {
  /** Get the Page from AppPages Object */
  const page = AppPages.filter(({ name }) => name === pathName)[0];

  /** If no Page Exists, return 404 */
  if (!page) {
    return '/404';
  }

  /** If no Params, then, return the Page Route */
  if (_.isEmpty(params)) {
    return page.routePage ?? page.path;
  }

  /** Else, build the Path using Provided Params */
  let { path } = page;

  Object
    .getOwnPropertyNames(params)
    .forEach((key) => {
      /** Replace Param on Path */
      path = path.replace(new RegExp(`:${key}`, 'g'), params[key]);
    });

  return path.replace(/\?/g, '');
};

/** Add Props to Route Directly to Default Public and Private */
RouteTo.DefaultPublic = RouteTo(defaultPublic);
RouteTo.DefaultPrivate = RouteTo(defaultPrivate);


/* --------
 * Build the Function to Get Page Title
 * -------- */
const PageTitle = (pathName: TAppPage): string => AppPages.filter(({ name }) => name === pathName)[0]?.title ?? 'Untitled';

/** Add Props to Get Current Page Title */
PageTitle.Current = (): string => AppPages
  .filter(({ path }) => matchPath(window.location.pathname, { path, exact: true }))[0]?.title ?? 'Untitled';

/** Define a Function to get Current Page */
const CurrentPage = (): IAppPage => AppPages
  .find(({ path }) => matchPath(window.location.pathname, { path, exact: true }));

export default AppPages;

export { RouteTo, PageTitle, CurrentPage };
