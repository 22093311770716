import * as React from 'react';
import { withRouter, RouteChildrenProps } from 'react-router-dom';

import { compose } from 'recompose';

import slugify from 'slugify';
import * as H from 'history';

import { CurrentPage, IAppPage } from '../../router-pages';

/** Extend the RouterWatcher Props */
export interface RouterWatcherOuterProps {
  /** On Change Function Handler */
  onChange?: (current: IAppPage, location: H.Location, history: H.History) => void;

  /** On Hash Changed Function Handler */
  onHashChange?: (location: H.Location, history: H.History) => void;

  /** Check if must set ClassName depending on route */
  setRouteClassName?: boolean;
}

declare interface RouterWatcherInnerProps extends RouterWatcherOuterProps, RouteChildrenProps { }

/** Build the Watcher Component */
class RouterWatcherBase extends React.Component<RouterWatcherInnerProps> {

  appMountNode: HTMLElement = null;

  /* --------
   * On Route and Hash Change Handler
   * -------- */
  handleRouteChange = (prevPathName: string, currPathName: string) => {
    /** Get Props */
    const {
      setRouteClassName,
      location,
      history,
      onChange
    } = this.props;

    if (this.appMountNode && setRouteClassName) {
      /** Get Slugs */
      const prevPathRoot = (prevPathName && prevPathName.split('/')) || [];
      const currPathRoot = (currPathName && currPathName.split('/')) || [];

      /** Remove previous class if exists */
      if (prevPathRoot.length) {
        this.appMountNode.classList.remove(
          ...prevPathRoot.filter(c => (typeof c === 'string' && c.length)).map(path => slugify(path).toLowerCase().trim())
        );
      }

      /** Add current class if exists */
      if (currPathRoot.length) {
        this.appMountNode.classList.add(
          ...currPathRoot.filter(c => (typeof c === 'string' && c.length)).map(path => slugify(path).toLowerCase().trim())
        );
      }
    }

    /** Invoke the onChange Function, if exists */
    if (typeof onChange === 'function') {
      onChange(CurrentPage(), location, history);
    }
  }

  handleHashChange = () => {
    /** Get the Handler */
    const {
      onHashChange,
      location,
      history
    } = this.props;

    if (typeof onHashChange === 'function') {
      onHashChange(location, history);
    }
  }


  /* --------
   * Component LifeCycle Handler
   * -------- */
  componentDidMount() {
    /** Get Actual Path */
    const { location: { pathname } } = this.props;

    /** If the appMountNodeID exists, get the DOM Element */
    this.appMountNode = document.documentElement;

    /** Launch the first handleRouteChange */
    this.handleRouteChange(null, pathname);
  }

  componentDidUpdate(prevProps: RouterWatcherInnerProps) {
    /** Get Actual Props */
    const { location: { pathname, hash } } = this.props;

    /** Get Prev Props */
    const { location: { pathname: prevPathname, hash: prevHash } } = prevProps;

    /** Check if Route is Changed */
    if (prevPathname !== pathname) {
      this.handleRouteChange(prevPathname, pathname);
      return;
    }

    /** Check if hash is changed */
    if (prevHash !== hash) {
      this.handleHashChange();
    }
  }

  render() {
    return null;
  }

}

/** Build the Composed Component */
const RouterWatcher = compose<RouterWatcherInnerProps, RouterWatcherOuterProps>(withRouter)(RouterWatcherBase);

RouterWatcher.displayName = 'RouterWatcher';

export default RouterWatcher;
