import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import EventAvailableRounded from '@material-ui/icons/EventAvailableRounded';

import { RouteTo } from '../../router';
import { will } from '../../utils/will';

import { useClient } from '../../hoc/with-client/client.context';

import { PageView } from '../../ui/PageView';
import { Appointment } from '../../interfaces';
import dayjs from 'dayjs';
import { Button } from '../../component/Button';


/* --------
 * Component Props
 * -------- */
interface ConfirmState {
  appointment: Appointment;
  isLoaded: boolean;
  error: React.ReactNode;
  errorTitle: string;
  errorContent: React.ReactNode;
  errorButton: string;
  token: string;
}

interface ConfirmProps extends RouteComponentProps {}


const initialState: ConfirmState = {
  appointment: null,
  isLoaded: false,
  error: null,
  errorTitle: null,
  errorContent: null,
  errorButton: null,
  token: null
};


/* --------
 * Component Definition
 * -------- */
function Confirm(props: ConfirmProps) {

  const { location, history } = props;

  /** Set initial State */
  const [state, setState] = React.useState<ConfirmState>(initialState);

  /** Set the Confirmed State */
  const [confirmStatus, setConfirmStatus] = React.useState({
    isConfirmed  : false,
    isConfirming : false
  });

  /** Get Dependencies */
  const client = useClient();

  /** Use an Effect to load data on component load */
  React.useEffect(() => {
    /** Get URL Params */
    const queryParams = new URLSearchParams(location.search);

    /** Get Token and Shared Code */
    const token: string = queryParams.get('token');
    const sharedCode: string = queryParams.get('sharedcode');

    /** If a params is missing, return to Dashboard */
    if (!token || !sharedCode) {
      history.push(RouteTo('Dashboard'));
      return;
    }

    /** Check Data */
    async function checkQueryData() {
      /** Await */
      await new Promise(resolve => setTimeout(resolve, 1000));

      /** Build a fresh base state */
      const baseState: ConfirmState = {
        ...initialState,
        isLoaded: true,
        token
      };

      /** Get the Appointment data */
      const [err, appointmentData] = await will(
        client.get<Appointment>(`/cdr/appointment/${sharedCode}`, { withToken: token })
      );

      /** Check Appointment exists */
      if (err && err.status === 204) {
        setState({
          ...baseState,
          error: 'No Content',
          errorTitle: 'Nessun Appuntamento',
          errorContent: 'Nessun Appuntamento Trovato',
          errorButton: 'Torna Indietro'
        });
        return;
      }

      /** Check Appointment or Token is Valid */
      let isAppointmentValid = false;

      /** Check appointment creation date, to agglomerate error */
      if (appointmentData && appointmentData.creationDate) {
        /** Get the Creation Date */
        const now = dayjs();
        const creationDate = dayjs(appointmentData.creationDate);
        /** Check date is in range */
        if (creationDate.add(29, 'minute').isBefore(now)) {
          isAppointmentValid = false;
        }
        else {
          isAppointmentValid = true;
        }
      }

      if (err || !isAppointmentValid || !appointmentData || appointmentData.id === 0) {
        setState({
          ...baseState,
          error: err?.statusText ?? true,
          errorTitle: 'Autorizzazione Scaduta',
          errorContent: (
            <div>
              Il tempo disponibile per la conferma è scaduto.<br/>
              Ti invitiamo a creare una nuova Prenotazione
            </div>
          )
        });

        return;
      }

      /** Set the new State */
      setState({
        ...baseState,
        appointment: appointmentData
      });

    }

    /** Check provided data */
    checkQueryData();

  }, [location.search, history, client]);

  /** Create Handler to Return to Schedule */
  const handleErrorButtonClick = () => {
    history.push(RouteTo('Schedule'));
  };

  const handleConfirmAppointment = async () => {
    setConfirmStatus({ isConfirming: true, isConfirmed: false });
    const [err] = await will(
      client.put('/cdr/appointment', {
        data: {
          sharedCode: appointment.sharedCode,
          confirmed: true
        },
        withToken: state.token
      })
    );
    setConfirmStatus({ isConfirmed: !err, isConfirming: false });
  };

  /** Return Component */
  const {
    appointment,
    isLoaded,
    error,
    errorContent,
    errorTitle,
    errorButton
  } = state;

  const {
    isConfirmed,
    isConfirming
  } = confirmStatus;

  /** If component is still loading, show the loader */
  if (!isLoaded) {
    return (
      <PageView
        useLogo={false}
        icon={EventAvailableRounded}
        title='Il tuo Appuntamento'
        loading='Ricerca dei Dati della Prenotazione'
      />
    );
  }

  /** If component has an error show it */
  if (error) {
    return (
      <PageView
        useLogo={false}
        error={error}
        icon={EventAvailableRounded}
        title='Il tuo Appuntamento'
        errorTitle={errorTitle}
        errorContent={errorContent}
        errorButton={errorButton}
        onErrorButtonClick={handleErrorButtonClick}
      />
    );
  }

  const appointmentDate = dayjs(appointment.appointmentDate);

  return (
    <PageView
      useLogo={false}
      icon={EventAvailableRounded}
      title='Il tuo Appuntamento'
    >

      <Box mt={2} textAlign='center'>
        <Typography variant='caption' color='textSecondary'>
          Struttura
        </Typography>
        <Typography variant='h6'>
          {appointment.cdr.name}
        </Typography>
      </Box>

      <Box mt={1} textAlign='center'>
        <Typography variant='caption' color='textSecondary'>
          Data
        </Typography>
        <Typography variant='h6'>
          {appointmentDate.format('DD MMMM YYYY')}
        </Typography>
      </Box>

      <Box mt={1} textAlign='center'>
        <Typography variant='caption' color='textSecondary'>
          Ora
        </Typography>
        <Typography variant='h6'>
          {appointmentDate.format('HH:mm')} - {appointmentDate.add(30, 'm').format('HH:mm')}
        </Typography>
      </Box>

      <Box mt={1} textAlign='center'>
        <Typography variant='caption' color='textSecondary'>
          Codice Prenotazione
        </Typography>
        <Typography variant='h6'>
          {appointment.sharedCode}
        </Typography>
      </Box>

      <Box mt={3} textAlign='center'>
        {appointment.confirmed && !appointment.deleted && (
          <Typography variant='h6' color='secondary'>
            Appuntamento già Confermato
          </Typography>
        )}

        {appointment.deleted && (
          <div>
            <Typography color='secondary'>
              Appuntamento Eliminato
            </Typography>
            <Typography>
              Questo appuntamento risulta eliminato.
            </Typography>
          </div>
        )}

        {!appointment.deleted && !appointment.confirmed && (
          <div>

            {isConfirmed && (
              <Typography variant='h6' color='secondary'>
                Appuntamento Confermato
              </Typography>
            )}

            {!isConfirmed && (
              <Box mt={3}>
                <Button
                  color='secondary'
                  loading={isConfirming}
                  content='Conferma Appuntamento'
                  onClick={handleConfirmAppointment}
                />
              </Box>
            )}
          </div>
        )}
      </Box>

    </PageView>
  );

}

export default Confirm;
