import * as React from 'react';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const Copyright: React.FunctionComponent = () => (
  <Box mt={8}>
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='http://protea.srl/' target='_blank'>
        Protea
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  </Box>
);

export default Copyright;
