import * as React from 'react';

import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import HelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MUISelect from '@material-ui/core/Select';

/* --------
 * Component Props
 * -------- */
export interface SelectOptions {
  value: string | number;
  label: React.ReactNode;
}

export interface SelectExtendedProps {
  label?: string;
  helperText?: React.ReactNode;
  options: SelectOptions[];
  value: string | number;
  onSelectChange?: (event: React.ChangeEvent<{ name?: string, value: unknown }>, child: React.ReactNode) => void;
}

export type SelectProps = SelectExtendedProps & FormControlProps;


/* --------
 * Component Definition
 * -------- */
export function Select(props: SelectProps) {

  const {
    label,
    helperText,
    options,
    value,
    onSelectChange,
    ...rest
  } = props;

  const labelWidth = label ? label.length * 9 : 0;

  return (
    <FormControl
      {...rest}
      fullWidth
      variant='outlined'
    >
      {label && (<InputLabel>{label}</InputLabel>)}
      <MUISelect
        labelWidth={labelWidth}
        value={value}
        onChange={onSelectChange}
      >
        {options.map(({ value: optionValue, label: optionLabel }) => (
          <MenuItem
            key={optionValue}
            value={optionValue}
          >{optionLabel}</MenuItem>
        ))}
      </MUISelect>
      {helperText && (<HelperText>{helperText}</HelperText>)}
    </FormControl>
  );
}
