import * as React from 'react';
import dayjs from 'dayjs';

import Alert from '@material-ui/lab/Alert';
import MUIButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';

import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

import isEmail from 'validator/lib/isEmail';

import { Button } from '../../../component/Button';
import { useClient } from '../../../hoc/with-client/client.context';
import { will } from '../../../utils/will';


/* --------
 * Component Styles
 * -------- */
const useStyles = makeStyles((theme) => ({

  dialogTitle: {
    textAlign: 'center'
  },

  dialogContent: {
    textAlign: 'center'
  },

  dialogIcon: {
    display: 'block',
    margin: '0 auto',
    height: '3.25rem',
    width: '3rem',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),

    '& > svg': {
      fontSize: '3rem'
    }
  },

  iconSuccess: {
    color: green[500]
  },

  iconError: {
    color: red[500]
  }

}));


/* --------
 * Component Props
 * -------- */
export interface ConfirmAppointmentProps {
  appointmentDate: dayjs.Dayjs;
  email: string;
  acceptedTOS: boolean;
  onSubmitted?: () => void;
  onChangeTimeRequest?: () => void;
}


/* --------
 * Component Declaration
 * -------- */
export default function ConfirmAppointment(props: ConfirmAppointmentProps) {

  const {
    email,
    appointmentDate,
    acceptedTOS,
    onSubmitted,
    onChangeTimeRequest
  } = props;

  const client = useClient();

  const [isLoading, setLoading] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [errorCode, setError] = React.useState<'generic' | 'slot-unavailable' | 'double'>(null);
  const [isSuccess, setSuccess] = React.useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
    setError(null);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setError(null);
  };

  const handleErrorDialogClose = () => {
    setOpen(false);
    setError(null);
    if (typeof onChangeTimeRequest === 'function') {
      onChangeTimeRequest();
    }
  };

  const handleConfirm = async () => {
    /** Set Loading */
    setLoading(true);
    /** Make the Request */
    const [err] = await will(client.post('/cdr/appointment', {
      data: {
        appointmentDate: appointmentDate.format('YYYY-MM-DDTHH:mm:ss'),
        confirmed: client.autoConfirmAppointment,
        accountId: client.operatorID,
        email
      }
    }));

    setLoading(false);

    if (err) {
      if (err.status === 409) {
        setError('slot-unavailable');
      }
      else if (err.status === 406) {
        setError('double');
      }
      else {
        setError('generic');
      }
    }
    else {
      setSuccess(true);
    }
  };

  const handleErrorClose = () => {
    setError(null);
  };

  const isDisabled = !appointmentDate || !isEmail(email) || !acceptedTOS;

  const classes = useStyles();

  return (
    <React.Fragment>

      {/* Modal Toggle */}
      <Button
        fullWidth
        color='primary'
        disabled={isDisabled}
        content='Prenota'
        onClick={handleDialogOpen}
        loading={isLoading}
      />

      {/* Confirm Appointment */}
      {!isDisabled && (
        <Dialog
          fullWidth
          disableEscapeKeyDown={isLoading}
          disableBackdropClick={isLoading}
          open={isOpen}
          maxWidth='xs'
          onClose={handleDialogClose}
        >
          <DialogTitle>Conferma Prenotazione?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              L'appuntamento è stato selezionato per{' '}
              <b>{appointmentDate.format('dddd DD MMMM YYYY')}</b> alle <b>{appointmentDate.format('HH:mm')}</b>.<br/>
              A breve ti verrà inviata una email all'indirizzo <b>{email}</b> con un link necessario alla conferma della prenotazione.<br />
              Avrai a disposizione <b>30 minuti</b> di tempo per cliccare sul link e confermare il tuo appuntamento.<br />
              Prima di continuare ti invitiamo a controllare i dati inseriti.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MUIButton disabled={isLoading} color='primary' onClick={handleDialogClose}>
              Annulla
            </MUIButton>
            <Button
              color='primary'
              variant='text'
              content='Conferma'
              loading={isLoading}
              onClick={handleConfirm}
            />
          </DialogActions>
        </Dialog>
      )}

      {/* Confirmed Appointment */}
      {isSuccess && (
        <Dialog
          open
          maxWidth='xs'
          onClose={handleDialogClose}
        >
          <DialogTitle className={classes.dialogTitle}>
            <Icon className={`${classes.dialogIcon} ${classes.iconSuccess}`}>
              <CheckCircleOutlineRoundedIcon />
            </Icon>
            Mail Inviata
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              Ti ricordo che per confermare la prenotazione devi necessariamente{' '}
              controllare la tua casella di posta e cliccare sul link che ti abbiamo inviato
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='text'
              content='Chiudi'
              onClick={onSubmitted}
            />
          </DialogActions>
        </Dialog>
      )}

      {/* Slot unavailable error */}
      {errorCode && errorCode === 'slot-unavailable' && (
        <Dialog
          open
          disableEscapeKeyDown
          disableBackdropClick
          maxWidth='xs'
        >
          <DialogTitle className={classes.dialogTitle}>
            <Icon className={`${classes.dialogIcon} ${classes.iconError}`}>
              <CancelOutlinedIcon />
            </Icon>
            Orario non più Disponibile
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              L'orario selezionato non risulta più disponibile
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='text'
              content='Cambia Orario'
              onClick={handleErrorDialogClose}
            />
          </DialogActions>
        </Dialog>
      )}

      {/* Double Appointment */}
      {errorCode && errorCode === 'double' && (
        <Dialog
          open
          disableEscapeKeyDown
          disableBackdropClick
          maxWidth='xs'
        >
          <DialogTitle className={classes.dialogTitle}>
            <Icon className={`${classes.dialogIcon} ${classes.iconError}`}>
              <CancelOutlinedIcon />
            </Icon>
            Prenotazione Impossibile
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>
              Risulta che tu abbia già prenotato un Appuntamento presso questa Struttura.<br />
              Se desidere modificare il tuo appuntamento devi prima annullare quello precedente.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='text'
              content='Torna'
              onClick={handleDialogClose}
            />
          </DialogActions>
        </Dialog>
      )}

      {/* Error Snackbar */}
      {errorCode && errorCode === 'generic' && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={7000}
          onClose={handleErrorClose}
        >
          <Alert severity='error'>
            Si è verificato un errore durante la prenotazione. Riprova
          </Alert>
        </Snackbar>
      )}

    </React.Fragment>
  );
}
