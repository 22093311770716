import * as React from 'react';
import dayjs from 'dayjs';

import { RouteChildrenProps } from 'react-router-dom';

import isEmail from 'validator/lib/isEmail';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';

import { RouteTo } from '../../router';

import { Form } from '../../component/Form';
import { Input } from '../../component/Input';
import { Button } from '../../component/Button';

import ChooseTime from './Atoms/ChooseTime';
import ConfirmAppointment from './Atoms/ConfirmAppointment';
import { SlotAvailability } from './utils/calendarAvailabilities';
import ChooseDate from './Atoms/ChooseDate';


/* --------
 * Component Styles
 * -------- */
const styles = createStyles((theme: Theme) => ({

  stepper: {
    backgroundColor: 'transparent',
    height: 140,
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },

  form: {
    padding: theme.spacing(0, 5)
  },

  tosCheckbox: {
    marginTop: theme.spacing(2),

    '& > .MuiFormControlLabel-label': {
      fontSize: '13px'
    }
  },

  buttonContainer: {
    flexGrow: 1,
    marginTop: theme.spacing(4)
  }

}));


/* --------
 * Component Props & State
 * -------- */
type ScheduleClasses = 'stepper' | 'form' | 'tosCheckbox' | 'buttonContainer';

interface ScheduleProps extends RouteChildrenProps, WithStyles<ScheduleClasses> {}

interface ScheduleState {
  currentStep: number;
  date: dayjs.Dayjs;
  slotsAvailable: SlotAvailability[];
  appointmentDateTime: dayjs.Dayjs;
  helperEmailText: string;
  email: string;
  hasAcceptedTOS: boolean;
  hasAcceptedInstructions: boolean;
}


/* --------
 * Component Declaration
 * -------- */
class Schedule extends React.Component<ScheduleProps, ScheduleState> {

  static getStepLabel(ix: number) {
    switch (ix) {
      case 0:
        return 'Scegli un Giorno';

      case 1:
        return 'Scegli un\'Ora';

      case 2:
        return 'Inserisci la tua Email';

      default:
        return 'Unknow Step';
    }
  }

  today: dayjs.Dayjs = dayjs();

  state: ScheduleState = {
    currentStep             : 0,
    slotsAvailable          : [],
    date                    : null,
    appointmentDateTime     : null,
    helperEmailText         : '',
    email                   : '',
    hasAcceptedTOS          : false,
    hasAcceptedInstructions : false
  };

  handleSelectDate = (date: dayjs.Dayjs, slotsAvailable: SlotAvailability[]) => {
    this.setState((curr) => ({
      date,
      slotsAvailable,
      currentStep: curr.currentStep <= 1 ? 1 : curr.currentStep
    }));
  }

  handleTimeChange = (slot?: dayjs.Dayjs) => {
    if (slot) {
      this.setState((curr) => ({
        appointmentDateTime: slot,
        currentStep: curr.currentStep <= 2 ? 2 : curr.currentStep
      }));
    }
    else {
      this.setState({
        appointmentDateTime: null,
        currentStep: 1
      });
    }
  }

  handleRemoveSelectedSlot = () => {
    this.setState((curr) => ({
      appointmentDateTime: null,
      currentStep: 1,
      slotsAvailable: curr.slotsAvailable.filter(({ slot }) => !slot.isSame(curr.appointmentDateTime))
    }));
  }

  handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: e.target.value,
      helperEmailText: isEmail(e.target.value) ? '' : 'Inserisci una Email valida'
    });
  }

  handleTOSCheckChange = () => {
    this.setState((curr) => ({
      hasAcceptedTOS: !curr.hasAcceptedTOS
    }));
  }

  handleInstructionCheckChange = () => {
    this.setState((curr) => ({
      hasAcceptedInstructions: !curr.hasAcceptedInstructions
    }));
  }

  handleReturnToDashboard = () => {
    const { history } = this.props;
    history.push(RouteTo('Dashboard'));
  }

  render() {

    const {
      currentStep,
      date,
      slotsAvailable,
      appointmentDateTime,
      helperEmailText,
      hasAcceptedTOS,
      hasAcceptedInstructions,
      email
    } = this.state;

    const { classes } = this.props;

    return (
      <Container component='main' maxWidth='sm'>
        <Stepper activeStep={currentStep} alternativeLabel className={classes.stepper}>
          {[0, 1, 2].map((index) => (
            <Step key={index}>
              <StepLabel>{index === currentStep && Schedule.getStepLabel(index)}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Form className={classes.form}>

          <ChooseDate
            onSelectDate={this.handleSelectDate}
          />

          {date && (
            <ChooseTime
              value={appointmentDateTime}
              options={slotsAvailable.filter(({ slot }) => slot.isAfter(this.today))}
              onChange={this.handleTimeChange}
            />
          )}

          {appointmentDateTime && (
            <div>
              <Input
                error={!!helperEmailText}
                helperText={helperEmailText}
                label='Indirizzo Email'
                autoComplete='email'
                type='email'
                onChange={this.handleEmailChange}
              />

              <FormControlLabel
                className={classes.tosCheckbox}
                label={'Accetto di recarmi presso la struttura seguendo tutte le normative vigenti dell’attuale DCPM'}
                control={<Checkbox checked={hasAcceptedTOS} onChange={this.handleTOSCheckChange} name='tos' />}
              />

              <FormControlLabel
                className={classes.tosCheckbox}
                label={'Accetto di rispettare le disposizioni che mi verranno comunicate in loco'}
                control={<Checkbox checked={hasAcceptedInstructions} onChange={this.handleInstructionCheckChange} name='tos' />}
              />
            </div>
          )}

          <Grid container spacing={2} className={classes.buttonContainer}>
            <Grid item xs={6}>
              <Button
                fullWidth
                color='secondary'
                content='Annulla'
                onClick={this.handleReturnToDashboard}
              />
            </Grid>
            <Grid item xs={6}>
              <ConfirmAppointment
                email={email}
                appointmentDate={appointmentDateTime}
                acceptedTOS={hasAcceptedTOS && hasAcceptedInstructions}
                onSubmitted={this.handleReturnToDashboard}
                onChangeTimeRequest={this.handleRemoveSelectedSlot}
              />
            </Grid>
          </Grid>

        </Form>
      </Container>
    );
  }
}

export default withStyles(styles)(Schedule);
