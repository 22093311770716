import * as React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';


/* --------
 * Component Props
 * -------- */
export interface FormExtendedProps {}

export type FormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> & FormExtendedProps;


/* --------
 * Component Styles
 * -------- */
const useStyles = makeStyles((theme) => ({

  form: {
    width: '100%'
  }

}));


/* --------
 * Component Definition
 * -------- */
export function Form(props: React.PropsWithChildren<FormProps>): React.ReactElement {

  const {
    className,
    children,
    onSubmit,
    ...rest
  } = props;

  const classes = useStyles();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    /** Prevent Original Submit Behaviour */
    e.preventDefault();
    e.stopPropagation();
    /** Call passed onSubmit function */
    if (typeof onSubmit === 'function') {
      onSubmit(e);
    }
  };

  const formClasses = clsx(
    classes.form,
    className
  );

  return (
    <form {...rest} noValidate className={formClasses} onSubmit={handleSubmit}>
      {children}
    </form>
  );

}
