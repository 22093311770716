import * as React from 'react';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import MUIButton, { ButtonProps as MUIButtonProps } from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

/* --------
 * Component Props
 * -------- */
export interface ButtonExtendedProps {
  content?: React.ReactNode;
  loading?: boolean;
}

export type ButtonProps = ButtonExtendedProps & MUIButtonProps;


/* --------
 * Component Styles
 * -------- */
const useStyles = makeStyles({

  button: {
    position: 'relative',
    textDecoration: 'none'
  },

  fullWidthContainer: {
    width: '100%',
    flexGrow: 1
  },

  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }

});


/* --------
 * Component Declaration
 * -------- */
export function Button(props: ButtonProps): React.ReactElement<ButtonProps> {

  const {
    className,
    disabled,
    fullWidth,
    loading,
    content,
    ...rest
  } = props;

  const classes = useStyles();

  const buttonClasses = clsx(
    classes.button,
    fullWidth && classes.fullWidthContainer,
    className
  );

  return (
    <div className={buttonClasses}>
      <MUIButton
        size='large'
        fullWidth={fullWidth}
        disabled={disabled || loading}
        variant='contained'
        {...rest}
      >
        {content}
      </MUIButton>
      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );

}
