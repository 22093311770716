import * as React from 'react';

import dayjs from 'dayjs';
import { compose } from 'recompose';

import Rollbar from 'rollbar';
import browserInfo from 'browser-info';

import Box from '@material-ui/core/Box';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayJSUtils from '@date-io/dayjs';
import * as dayjsItLocale from 'dayjs/locale/it';

import CssBaseline from '@material-ui/core/CssBaseline';

import {
  initClient,
  withClient,
  WithClientProps
} from './hoc/with-client/client.context';

import { AuthState } from './lib/client/client.interface';
import AppRouter from './router/app-router';

import PageLoader from './ui/PageLoader';
import { PageView } from './ui/PageView';

dayjs.locale(dayjsItLocale);

/* --------
 * AppBootstrap State Interface Definition
 * -------- */
interface AppBootstrapState extends AuthState {}


/* --------
 * AppBootstrap Component
 * -------- */
class AppBootstrap extends React.Component<WithClientProps, AppBootstrapState> {

  /** Auth State unsubscriber function */
  private authStateUnsubscriber: () => void = () => null;

  /** Init Rollbar Catcher */
  rollbarCatcher = new Rollbar({
    accessToken                : 'fbf9ebf77aa841c18abcc35b1435bbcd',
    captureIp                  : true,
    captureUncaught            : true,
    captureUnhandledRejections : true,
    environment                : 'CDREcoPortale',
    itemsPerMinute             : 30,
    enabled                    : true,
    payload                    : {
      browser: {
        ...browserInfo(),
        userAgent: window.navigator?.userAgent
      }
    },
    filterTelemetry: (e) => {
      if (e.type !== 'log') {
        return false;
      }

      return e.level === 'info' || e.level === 'warning' || e.level === 'debug';
    }
  });

  /** Component State Init using client */
  state: AppBootstrapState = this.props.client.authState;

  handleAuthStateChanged = (nextAuthState: AuthState) => {
    this.setState(nextAuthState);
  }

  /** On Component mount, Subscribe to Auth State Event */
  componentDidMount() {
    /** Save the Unsubscriber */
    this.authStateUnsubscriber = this.props.client.onAuthStateChanged(this.handleAuthStateChanged, this);
  }

  /** Unsubscribe on App Unmount */
  componentWillUnmount() {
    /** Fire the unsubscriber */
    this.authStateUnsubscriber();
  }

  /** AppBoostrap must rerender only if state will change */
  shouldComponentUpdate(_nextProps: WithClientProps, nextState: AppBootstrapState) {
    /** Compare State */
    const {
      isLoaded: currIsLoaded,
      userHasAuth: currUserHasAuth
    } = this.state;

    const {
      isLoaded: nextIsLoaded,
      userHasAuth: nextUserHasAuth
    } = nextState;

    return ((currIsLoaded !== nextIsLoaded) || (currUserHasAuth !== nextUserHasAuth));
  }

  render() {

    const {
      isLoaded,
      userHasAuth
    } = this.state;

    return (
      <MuiPickersUtilsProvider utils={DayJSUtils} locale={dayjsItLocale}>

        <CssBaseline />

        <AppRouter
          appName='EcoPortale'
          appState={{
            isInitiallyLoading: !isLoaded,
            isLoading: false,
            userHasAuth
          }}
          pageLoader={PageLoader}
        />

      </MuiPickersUtilsProvider>
    );
  }
}


/* --------
 * AppBostrap Composer Function
 * -------- */
export default compose(initClient, withClient)(AppBootstrap);
