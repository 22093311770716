import * as React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { Appointment } from '../../../interfaces';
import { Button } from '../../../component/Button';
import { useClient } from '../../../hoc/with-client/client.context';
import { will } from '../../../utils/will';
import dayjs from 'dayjs';


/* --------
 * Component Props
 * -------- */
export interface RemoveAppointmentProps {
  onDialogWantClose: (isDeleted: boolean) => void;
}

export interface RemoveAppointmentState {
  appointment: Appointment;
  getAppointmentError: string;
  isCheckingAppointment: boolean;
  isDeleting: boolean;
  sharedCode: string;
}


/* --------
 * Component Definition
 * -------- */
export default class RemoveAppointment extends React.Component<RemoveAppointmentProps, RemoveAppointmentState> {

  state: RemoveAppointmentState = {
    appointment: null,
    getAppointmentError: null,
    isCheckingAppointment: false,
    isDeleting: false,
    sharedCode: ''
  };

  handleMasterDialogClose = () => {
    const { onDialogWantClose } = this.props;
    onDialogWantClose(false);
  }

  handleSharedCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      sharedCode: e.target.value
    });
  }

  handleVerifySharedCodeClick = async () => {
    /** Get the Code */
    const { sharedCode } = this.state;
    /** Set Loading */
    this.setState({ isCheckingAppointment: true });
    /** Get Client and make request to get Appointment */
    const client = useClient();
    const [err, appointment] = await will(client.get<Appointment>(`/cdr/appointment/${sharedCode}`));

    this.setState({
      isCheckingAppointment: false,
      getAppointmentError: err && err.status !== 404 ? 'generic-error' : !appointment ? 'not-found' : null,
      appointment
    });
  }

  handleConfirmDelete = async () => {
    /** Get the Code */
    const { sharedCode } = this.state;
    /** Set Loading */
    this.setState({ isDeleting: true });
    /** Get Client and make request to get Appointment */
    const client = useClient();
    const [, result] = await will(client.delete('/cdr/appointment', {
      params: { sharedCode }
    }));
    if (result) {
      this.props.onDialogWantClose(true);
    }
    else {
      this.setState({ isDeleting: false });
    }
  }

  handleCancelConfirmDelete = () => {
    this.setState({ appointment: null });
  }

  render() {

    const {
      isCheckingAppointment,
      getAppointmentError,
      appointment,
      sharedCode,
      isDeleting
    } = this.state;

    return (
      <React.Fragment>
        <Dialog
          open
          fullWidth
          maxWidth='xs'
          disableBackdropClick={isCheckingAppointment}
          disableEscapeKeyDown={isCheckingAppointment}
          onClose={this.handleMasterDialogClose}
        >

          <DialogTitle>Annulla Appuntamento</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Inserisci il codice dell'Appuntamento che vuoi annullare.<br />
              Puoi trovare il codice all'interno dell'Email di conferma che ti è stata inviata.
            </DialogContentText>

            <TextField
              autoFocus
              fullWidth
              margin='dense'
              id='sharedCode'
              label='Codice Appuntamento'
              onChange={this.handleSharedCodeChange}
            />


            {getAppointmentError && (
              <DialogContentText color='error'>
                {getAppointmentError === 'not-found'
                  ? (
                    <span>
                      Prenotazione non trovata<br/>
                      verifica il codice inserito.
                    </span>
                  )
                  : (
                    <span>
                      Si è verificato un Errore<br />
                      Riprova più tardi.
                    </span>
                  )}
              </DialogContentText>
            )}

          </DialogContent>

          <DialogActions>
            <Button
              color='primary'
              variant='text'
              content='Verifica Codice'
              disabled={!sharedCode}
              loading={isCheckingAppointment}
              onClick={this.handleVerifySharedCodeClick}
            />
          </DialogActions>

        </Dialog>

        {appointment && (
          <Dialog
            open
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth='xs'
          >

            <DialogTitle>Elimina Appuntamento</DialogTitle>

            <DialogContent>
              <DialogContentText>
                Sei sicuro di voler annullare l'appuntamento presso<br />
                <b>{appointment.cdr.name}</b><br/>
                preso per il giorno<br />
                <b>{dayjs(appointment.appointmentDate).format('DD MMMM YYYY')} alle ore {dayjs(appointment.appointmentDate).format('HH:mm')}</b>
              </DialogContentText>

              {appointment.deleted && (
                <DialogContentText color='error'>
                  Appuntamento già eliminato.
                </DialogContentText>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                disabled={isDeleting}
                color='primary'
                variant='text'
                content='No'
                onClick={this.handleCancelConfirmDelete}
              />
              <Button
                loading={isDeleting}
                disabled={appointment.deleted}
                color='primary'
                variant='text'
                content='Si'
                onClick={this.handleConfirmDelete}
              />
            </DialogActions>

          </Dialog>
        )}
      </React.Fragment>
    );

  }

}
