import * as React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

/* --------
 * Component Props
 * -------- */
export interface ContainerTitleProps {
  icon?: React.ComponentType;
  subTitle?: React.ReactNode;
  title?: React.ReactNode;
}


/* --------
 * Component Styles
 * -------- */
const useStyles = makeStyles((theme) => ({

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },

  pageHeader: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  title: {
    textAlign: 'center'
  },

  subTitle: {
    textAlign: 'center'
  }

}));


/* --------
 * Component Definition
 * -------- */
export default function ContainerTitle(props: ContainerTitleProps): React.ReactElement {

  const {
    icon: IconComponent,
    title,
    subTitle
  } = props;

  const classes = useStyles();

  if (!IconComponent && !title && !subTitle) {
    return null;
  }

  return (
    <div className={classes.pageHeader}>
      {/* Page Icon */}
      {IconComponent && (
        <Avatar className={classes.avatar}>
          <IconComponent />
        </Avatar>
      )}

      {/* Page Title */}
      {title && (
        <Typography className={classes.title} component='h1' variant='h6'>
          {title}
        </Typography>
      )}

      {/* Page SubTitle */}
      {subTitle && (
        <Typography className={classes.subTitle} component='h3' color='textSecondary'>
          {subTitle}
        </Typography>
      )}
    </div>
  );
}
