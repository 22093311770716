import * as React from 'react';
import cx from 'classnames';

import { BrowserRouter as Router, Switch, matchPath, RouteComponentProps } from 'react-router-dom';

import { NotFound } from '../../modules';

import AppPages from '../router-pages';

import ComponentMap from './router-component-map';

import RouterWatcher from './router-watcher';
import RouterWrapper, { IRouterWrapperProps, AppState } from './router-wrapper';


/* --------
 * App Router Interfaces
 * -------- */
export interface AppRouterExtraContentProps {
  /** The Current Auth State */
  appState: AppState;

  /** Any other props passed */
  [key: string]: any;
}

export interface AppRouterProps extends AppRouterExtraContentProps {
  /** The App Name, prepended to Page Titlte */
  appName?: string;

  /** Custom bottom page content */
  bottomPageContent?: React.ComponentType<any>;

  /**
   * Calc the Next Route.
   * If this function return a valid string
   * then the Router will point to returned route.
   * If it will return null, then route
   * will continue as always
   */
  getNextRoute?: (props: IRouterWrapperProps, routeProps: RouteComponentProps<any, any, {}>) => string;

  /** Check if must render Navbar */
  hasNavbar?: boolean;

  /** Check if must Render Sidebar */
  hasSidebar?: boolean;

  /** The Navbar Element */
  navbar?: React.ComponentType<any>;

  /** User defined Page Content Classes */
  pageContentClassNames?: string;

  /** The Page Loader Component, visible while loading */
  pageLoader?: React.ComponentType<any>;

  /** The Sidebar Element */
  sidebar?: React.ComponentType<any>;

  /** Custom Top Page Content */
  topPageContent?: React.ComponentType<any>;

  /** User defined View Content Classes */
  viewContentClassNames?: string;
}

export interface AppRouterState {
  /** Check if the Route has the Navbar */
  hasNavbar: boolean;

  /** Check if the Route has the Sidebar */
  hasSidebar: boolean;
}


/* --------
 * App Router Component
 * -------- */
export default class AppRouter extends React.Component<AppRouterProps, AppRouterState> {

  /* --------
   * Static Props
   * -------- */
  static displayName = 'AppRouter';


  /* --------
   * State Init
   * -------- */
  state: AppRouterState = {
    hasNavbar: false,
    hasSidebar: false
  };


  /* --------
   * Route Change Handler
   * -------- */
  checkSidebarAndNavbarPresence = () => {
    /** Get master props */
    const {
      hasNavbar: routerHasNavbar,
      hasSidebar: routerHasSidebar,
    } = this.props;

    /** Get current state */
    const {
      hasNavbar: currHasNavbar,
      hasSidebar: currHasSidebar
    } = this.state;

    /** Get the Current Page */
    const currentPage = AppPages.find(({ path }) => (
      matchPath(window.location.pathname, { path, exact: true })
    ));

    /** Check if page has navbar or sidebar */
    const {
      hasNavbar: pageHasNavbar = !(currentPage?.isPublic),
      hasSidebar: pageHasSidebar = !(currentPage?.isPublic)
    } = currentPage || {};

    /** Check if router wrapper must have sidebar and navbar */
    const hasNavbar = !!(pageHasNavbar && routerHasNavbar);
    const hasSidebar = !!(pageHasSidebar && routerHasSidebar);

    /** If has navbar or has sidebar is changed, update state */
    if ((hasNavbar !== currHasNavbar) || (hasSidebar !== currHasSidebar)) {
      this.setState({ hasNavbar, hasSidebar });
    }
  }

  componentDidUpdate() {
    this.checkSidebarAndNavbarPresence();
  }


  /* --------
   * Main Render Function
   * -------- */
  render() {

    const {
      hasSidebar,
      hasNavbar
    } = this.state;

    const {
      appName,

      authState,
      getNextRoute,

      viewContentClassNames,
      pageContentClassNames,

      // Component Props
      navbar: Navbar,
      sidebar: SideBar,
      topPageContent: TopPageContent,
      bottomPageContent: BottomPageContent,
      pageLoader: PageLoader,

      appState,

      // Rest Props
      ...rest
    } = this.props;

    const viewClasses = cx('view-content', viewContentClassNames);
    const pageClasses = cx('page-content', pageContentClassNames);

    /** Build Props for Content */
    const extraContentProps = { authState, ...rest };
    const defaultRouteProps = {
      appName,
      getNextRoute,
      authState,
      hasNavbar: !!(hasNavbar && Navbar),
      hasSidebar: !!(hasSidebar && SideBar)
    };

    /** If App is still Loading, show the full Loader */
    if (appState.isInitiallyLoading) {
      document.title = [
        appName,
        'Loading...'
      ].filter(partial => typeof partial === 'string' && partial.length).join(' | ');
      return PageLoader ? <PageLoader /> : <h1>Loading</h1>;
    }

    return (
      <Router>

        {/* Append the Route Watcher */}
        <RouterWatcher setRouteClassName onChange={this.checkSidebarAndNavbarPresence} />

        {/* Append Navbar */}
        {hasNavbar && <Navbar {...extraContentProps} />}

        {/* Append SideBar */}
        {hasSidebar && <SideBar {...extraContentProps} />}

        {/* Build the Main View */}
        <div className={viewClasses}>

          {/* Prepend top Content */}
          {TopPageContent && <TopPageContent {...extraContentProps} />}

          {/* Build the Pages */}
          <div className={pageClasses}>
            <Switch>
              {/* Loop Each Defined Pages */}
            {AppPages.map(page => (
              <RouterWrapper
                key={page.name}
                Component={ComponentMap[page.name]}
                appState={appState}
                {...defaultRouteProps}
                {...page}
              />
            ))}
            {/* Add an Hook fot NotFound Pages */}
            <RouterWrapper
              Component={NotFound}
              appState={appState}
              isPublic={false}
              {...defaultRouteProps}
            />
            </Switch>
          </div>

          {/* Append bottom Content */}
          {BottomPageContent && <BottomPageContent {...extraContentProps} />}

        </div>

      </Router>
    );

  }

}
