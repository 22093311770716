import * as React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';


/* --------
 * Component Props
 * -------- */
export interface InputExtendedProps {
  loading?: boolean;
  readOnly?: boolean;
}

export type InputProps = TextFieldProps & InputExtendedProps;


/* --------
 * Component Styles
 * -------- */
const useStyles = makeStyles((theme) => ({

  fiscalCodeProgress: {
    color: theme.palette.text.secondary
  }

}));


/* --------
 * Component Definition
 * -------- */
export function Input(props: InputProps): React.ReactElement<TextFieldProps> {

  const {
    loading,
    readOnly,
    variant = 'outlined',
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <TextField
      required
      fullWidth
      variant={variant as any}
      margin='normal'
      InputProps={loading ? {
        readOnly,
        endAdornment: (
          <InputAdornment position='end'>
            <CircularProgress
              className={classes.fiscalCodeProgress}
              size='1.25rem'
            />
          </InputAdornment>
        )
      } : null}
      {...rest}
    />
  );

}
