import * as React from 'react';
import cx from 'classnames';

import { Route, Redirect, RouteComponentProps } from 'react-router-dom';

import { RouteTo, IAppPage } from '../../router-pages';

import { classByKey } from '../../../utils/classname-utils';

export interface IRouterWrapperProps extends IRouterWrapperStrictProps, IAppPage { }

export interface AppState {
  /** App is initially loading */
  isInitiallyLoading: boolean;
  /** App is loading and freezed */
  isLoading: boolean;
  /** Set if User has Auth */
  userHasAuth: boolean;
}

export interface IRouterWrapperStrictProps {
  /** Component to Use */
  Component: React.ComponentType<any>;

  appName?: string;

  /** Current App State */
  appState: AppState;

  /** Check if must set the with-navbar class */
  hasNavbar?: boolean;

  /** Check if must set the with-sidebar class */
  hasSidebar?: boolean;

  /**
   * Calc the Next Route.
   * If this function return a valid string
   * then the Router will point to returned route.
   * If it will return null, then route
   * will continue as always
   */
  getNextRoute?: (props: IRouterWrapperProps, routeProps: RouteComponentProps<any, any, {}>) => string;

  /** Check if page is Public */
  isPublic: boolean;

  /** Check if a page is Hybrid */
  isHybrid?: boolean;

  /** Any other Props */
  [key: string]: any;
}

const RouterWrapper: React.FunctionComponent<IRouterWrapperStrictProps> = (wrapperProps) => {

  /** Destructure Props */
  const {
    Component,
    appName,
    appState,
    isPublic,
    isHybrid,
    hasNavbar,
    hasSidebar,
    getNextRoute,
    ...rest
  } = wrapperProps;

  /** Return the Wrapped Route */
  return (
    <Route
      /** Append Others */
      {...rest}
      /** Custom Render Function */
      // tslint:disable-next-line: jsx-no-lambda
      render={(props) => {
        /**
         * Check if User could view current
         * route using authState and isPublic
         * Route component propery
         */
        let mustRedirectTo: string = null;

        /** If a function to get the next route exists, call it */
        if (typeof getNextRoute === 'function') {
          mustRedirectTo = getNextRoute(wrapperProps as IRouterWrapperProps, props);
        }

        /** If mustRedirectTo is not a valid string, calculate using Auth State */
        if (typeof mustRedirectTo !== 'string') {
          /**
           * If Page is a Public Page
           * and the user has auth, then
           * must redirect to the default
           * Private Page
           */
          if (isPublic && !isHybrid && appState.userHasAuth) {
            mustRedirectTo = RouteTo.DefaultPrivate;
          }

          /**
           * If page is Private and the
           * user has no auth, then
           * must be redirect to default public page
           */
          if (!isPublic && !isHybrid && !appState.userHasAuth) {
            mustRedirectTo = RouteTo.DefaultPublic;
          }
        }

        /** If must redirect to path is the same of this location, purge the value */
        if (mustRedirectTo === wrapperProps.location.pathname) {
          mustRedirectTo = null;
        }

        /**
         * If the user must be redirect to some page
         * then return the redirect component
         */
        if (mustRedirectTo) {
          return (
            <Redirect
              to={{ pathname: mustRedirectTo, state: { from: props.location } }}
            />
          );
        }

        /** If a Title Exists, set it */
        document.title = [
          appName,
          wrapperProps.title
        ].filter(partial => typeof partial === 'string' && partial.length).join(' | ');

        /**
         * If user could view current page
         * return the component after set/replace
         * body class list based on auth
         */
        const classes = cx(
          classByKey(appState.userHasAuth, 'has-auth'),
          classByKey(hasNavbar, 'with-navbar'),
          classByKey(hasSidebar, 'with-sidebar')
        );

        document.body.classList.value = classes;

        /** Return the Component with its props */
        return (
          <Component {...props} />
        );
      }}
    />
  );

};

RouterWrapper.displayName = 'RouterWrapper';

export default RouterWrapper;
