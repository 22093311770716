import React from 'react';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Input } from '../../../component/Input';

import { SlotAvailability } from '../utils/calendarAvailabilities';
import dayjs from 'dayjs';


/* --------
 * Component Props
 * -------- */
export interface ChooseTimeProps<T> {
  options: SlotAvailability[];
  onChange: (slot: dayjs.Dayjs) => void;
  value?: dayjs.Dayjs;
}


/* --------
 * Component Declaration
 * -------- */
export default function ChooseTime(props: ChooseTimeProps<any>) {

  const {
    options,
    onChange,
    value: propValue
  } = props;

  const [isOpen, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (!propValue) {
      setValue('');
    }
  }, [propValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue((e.target as HTMLInputElement).value);
  };

  const handleInputClick = () => {
    if (!options.length) {
      return;
    }

    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (typeof onChange === 'function') {
      /** Pass original options by label */
      onChange(options.find(({ startLabel, endLabel }) => (
        `${startLabel} - ${endLabel}` === value
      ))?.slot);
    }

    setOpen(false);
  };

  return (
    <React.Fragment>

      <Input
        readOnly
        disabled={!options.length}
        label='Orario'
        value={value}
        onClick={handleInputClick}
      />

      <Dialog
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='xs'
        open={isOpen}
      >
        <DialogTitle>Scegli un Orario</DialogTitle>
        <DialogContent dividers>
          <RadioGroup
            value={value}
            onChange={handleChange}
          >
            {options.map((option) => {
              const label = `${option.startLabel} - ${option.endLabel}`;

              return (
                <FormControlLabel
                  value={label}
                  key={label}
                  control={<Radio />}
                  label={label}
                />
              );
            })}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color='primary'>
            Annulla
          </Button>
          <Button onClick={handleSubmit} disabled={!value} color='primary'>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );

}
