import * as React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';


/* --------
 * Component Style
 * -------- */
const useStyles = makeStyles((theme) => ({

  backdrop: {
    backgroundColor: theme.palette.primary.main,
    flexDirection: 'column'
  },

  progress: {
    color: theme.palette.primary.light
  },

  title: {
    color: theme.palette.primary.light,
    display: 'block',
    marginTop: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium
  }
}));


/* --------
 * Component Definition
 * -------- */
const PageLoader: React.FunctionComponent = () => {
  /** Get Classes */
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} color='primary' open>
      <CircularProgress
        className={classes.progress}
        size='4rem'
      />

      <Typography className={classes.title} variant='h5'>
        Caricamento
      </Typography>
    </Backdrop>
  );
};


export default PageLoader;
