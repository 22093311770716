import * as React from 'react';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

/* --------
 * Component Props
 * -------- */
export interface ContainerErrorProps {
  error?: React.ReactNode;
  errorButton?: string;
  errorContent?: React.ReactNode;
  errorTitle?: React.ReactNode;
  onErrorButtonClick?: () => void;
}


/* --------
 * Component Styles
 * -------- */
const useStyles = makeStyles((theme) => ({

  errorBox: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(1.25, 1.5),
    margin: theme.spacing(3, 0, 1)
  },

  errorCaption: {
    display: 'block',
    margin: theme.spacing(2, 0)
  },

  errorButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.palette.error.contrastText,
    backgroundColor: red[300],
    borderColor: red[100],
    '&:hover': {
      backgroundColor: red[200]
    }
  }

}));


/* --------
 * Component Definition
 * -------- */
export default function ContainerError(props: ContainerErrorProps): React.ReactElement {

  const {
    error,
    errorTitle,
    errorContent,
    errorButton,
    onErrorButtonClick
  } = props;

  const classes = useStyles();

  if (!error) {
    return null;
  }

  return (
    <Paper className={classes.errorBox}>

      <Typography variant='subtitle1'>
        {errorTitle}
      </Typography>

      <Typography variant='subtitle2'>
        {errorContent}
      </Typography>

      {process.env.NODE_ENV === 'development' && typeof error !== 'boolean' && (
        <Typography variant='caption' className={classes.errorCaption}>
          {error}
        </Typography>
      )}

      {errorButton && (
        <Button
          variant='outlined'
          className={classes.errorButton}
          onClick={onErrorButtonClick}
        >
          {errorButton}
        </Button>
      )}

    </Paper>
  );

}
