import * as React from 'react';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';


/* --------
 * Component Props
 * -------- */
export interface ContainerLoaderProps {
  loading?: boolean | string;
}


/* --------
 * Component Styles
 * -------- */
const useStyles = makeStyles((theme) => ({
  boxContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(4)
  },

  progress: {
    width: '100%'
  },

  title: {
    display: 'block',
    marginTop: theme.spacing(2),
    textAlign: 'center'
  }
}));


/* --------
 * Component Definition
 * -------- */
export default function ContainerLoader(props: ContainerLoaderProps): React.ReactElement {

  const {
    loading
  } = props;

  const classes = useStyles();

  if (!loading) {
    return null;
  }

  return (
    <Box className={classes.boxContainer}>
      <LinearProgress className={classes.progress} />

      {typeof loading !== 'boolean' && (
        <Typography className={classes.title} variant='h6' color='textSecondary'>
          {loading}
        </Typography>
      )}
    </Box>
  );

}
